<template>
  <v-container>
    <v-card class="elevation-2 mx-auto" :loading="loading" max-width="600">
      <v-card-title>Edit Board Member</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <form>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                label="Name"
                v-model="form_data.name"
                :error-messages="form_error.name"
                outlined
                dense
                autofocus
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                label="Designation"
                v-model="form_data.designation"
                :error-messages="form_error.designation"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                label="Company"
                v-model="form_data.company"
                :error-messages="form_error.company"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                label="Address"
                v-model="form_data.address"
                :error-messages="form_error.address"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                label="LinkedIn URL"
                v-model="form_data.linkedin_url"
                :error-messages="form_error.linkedin_url"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-file-input
                v-model="form_data.update_profile_pic"
                accept="image/png, image/jpeg, image/jpg"
                :error-messages="form_error.profile_pic"
                prepend-icon="mdi-camera"
                label="Profile Image"
                outlined
                dense
              ></v-file-input>
            </v-col>
          </v-row>
          <v-btn
            class="mr-4"
            color="secondary"
            @click="upadteBoardMember"
            :loading="loading"
            :disabled="loading"
          >Update</v-btn>
        </form>
      </v-card-text>
    </v-card>
    <v-btn small color="secondary" fixed fab bottom left to="/board">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
 
import { mapMutations } from "vuex";
export default {
  name: "EditObject",
  data() {
    return {
      pages: [],
      positions: [],
      form_data: {},
      form_error: {},
      loading: false,
      data: {},
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getBoardMemeberDetails() {
      this.loading = true;
      let _self = this;      
      this.$axios.get(`admin/board_member/details/${this.$route.params.id}`)
        .then((res) => {
          _self.form_error = {};
          if (res.status) {
            let data = res.data.data;
            for (let key in data) {
              _self.form_data[key] = data[key];
            }
          } else {
            let errors = res.error;
            for (let key in errors) {
              console.log(errors[key]);
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          console.log(_self.form_data);
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    upadteBoardMember() {
      let _self = this;
      this.loading = "secondary";

      let serverData = JSON.parse(JSON.stringify(this._data.form_data));
      let formData = new FormData();
      for (let key in serverData) {
        formData.append(key, serverData[key]);
      }
      if (this.form_data.update_profile_pic) {
        formData.append("profile_pic", this.form_data.update_profile_pic);
      }
      this.$axios.put(`admin/board_member/edit/${this.$route.params.id}`, formData)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            _self.$router.push("/board");
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getBoardMemeberDetails();    
  },
};
</script>